import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import { InfoIcon } from '../icons/InfoIcon';
import { Text } from '../text/Text';

const Wrapper = styled(Flex)`
  svg {
    width: 15px;
    height: 15px;
    margin-top: 1px;
  }
  width: 50%;
`;

export const Description = ({ children, mt }) => {
  return (
    <Wrapper mt={mt}>
      <InfoIcon />
      <Text size={'xxs'} ml={'6px'} lh={2} flex={1} width="50%">
        {children}
      </Text>
    </Wrapper>
  );
};
