import React from 'react';
import i18n from 'i18next';
import { COLOR } from '../common';
import { VoteInteractionIcon } from '../../ui/icons/VoteInteractionIcon';
import { VoiceInteractionIcon } from '../../ui/icons/VoiceInteractionIcon';
import { TextInteractionIcon } from '../../ui/icons/TextInteractionIcon';
import { VideoInteractionIcon } from '../../ui/icons/VideoInteractionIcon';
import { GameInteractionIcon } from '../../ui/icons/GameInteractionIcon';
import { AvailableOption } from '../../ui/interactions/AvailableOption';

export const INTERACTION_AVAILABILITY_MAP = {
  finished: { label: i18n.t('Finished'), color: COLOR.brownishGrey },
  active: { label: i18n.t('Active'), color: COLOR.green },
  programmed: { label: i18n.t('Programmed'), color: COLOR.yellowishOrange },
  not_completed: { label: i18n.t('Not completed'), color: COLOR.brownishGrey },
};

export const getAlternativeAnswerMethods = (interaction) => {
  const alternativeMethods =  [
    { value: "no", label: i18n.t('No') },
    { value: "text", label: i18n.t('Yes (Text)') },
  ]
  if (interaction.type === "video") {
    alternativeMethods.push(
      { value: "audio_text", label: i18n.t('Yes (Vocal + Text)') },
    )
  }
  return alternativeMethods
}

export const INTERACTION_FRONTEND_TYPE_MAP = {
  multiple_choice_question: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    label: i18n.t('Multiple choice'),
  },
  recording_question: {
    iconNew: <VoiceInteractionIcon />,
    label: i18n.t('Voice participation'),
  },
  video: {
    icon: <img src="/VideoInteractionIcon.svg" alt="" />,
    iconNew: <VideoInteractionIcon />,
    label: i18n.t('Media participation'),
  },
  registration: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    label: i18n.t('Registration'),
  },
  single_choice_question: {
    icon: <img src="/VoteInteractionIcon.svg" alt="" />,
    iconNew: <VoteInteractionIcon />,
    label: i18n.t('Survey'),
  },
  sms: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    label: 'SMS',
  },
  message: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    iconNew: <TextInteractionIcon />,
    label: i18n.t('Text participation'),
  },
  game: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    iconNew: <GameInteractionIcon />,
    label: i18n.t('Games'),
  }
};

export const INTERACTION_FRONTEND_CATEGORY_MAP = {
  //multiple_choice_question: {
  //  icon: <img src="/OtherInteractionIcon.svg" alt="" />,
  //  label: i18n.t('Multiple choice'),
  //},
  vocal_participation: {
    iconNew: <VoiceInteractionIcon />,
    label: i18n.t('Voice participation'),
    description: i18n.t('vocal_participation_description')
  },
  image_participation: {
    icon: <img src="/VideoInteractionIcon.svg" alt="" />,
    iconNew: <VideoInteractionIcon />,
    label: i18n.t('Image participation'),
    description: i18n.t('image_participation_description')
  },
  video_participation: {
    icon: <img src="/VideoInteractionIcon.svg" alt="" />,
    iconNew: <VideoInteractionIcon />,
    label: i18n.t('Video participation'),
    description: i18n.t('video_participation_description')
  },
  //registration: {
  //  icon: <img src="/OtherInteractionIcon.svg" alt="" />,
  //  label: i18n.t('Registration'),
  //},
  survey: {
    icon: <img src="/VoteInteractionIcon.svg" alt="" />,
    iconNew: <VoteInteractionIcon />,
    label: i18n.t('Survey'),
    description: i18n.t('survey_description')
  },
  //sms: {
  //  icon: <img src="/OtherInteractionIcon.svg" alt="" />,
  //  label: 'SMS',
  //},
  text_participation: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    iconNew: <TextInteractionIcon />,
    label: i18n.t('Text participation'),
    description: i18n.t('text_participation_description')
  },
  game: {
    icon: <img src="/OtherInteractionIcon.svg" alt="" />,
    iconNew: <GameInteractionIcon />,
    label: i18n.t('Games'),
    description: i18n.t('games_description')
  }
};
export const MIN_CHOICE_COUNT = 2;
