import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { WithSteps } from '../steps/withSteps';
import { request } from '../utils/request';
import { WithChannel } from '../channels/withChannel';
import { WithInteraction } from '../../models/interactions/withInteraction';
import { useTranslation } from 'react-i18next';

import { API_ENDPOINTS } from '../../constants/common';

export const IA_REQUEST_STEPS = [
  i18n.t('Request'),
  i18n.t('Loading'),
  i18n.t('Response'),
];


export const WithRequestAI = () => {
  const { t } = useTranslation();
  const { id: interactionId } = useParams();
  const [promptContext, setPromptContext] = useState('');
  const [promptOutput, setPromptOutput] = useState('');
  const [prompt, setPrompt] = useState('');
  const [aiResponse, setAiResponse] = useState('');

  const { interaction, fetchInteraction } = WithInteraction({ interactionId });
  const { channel } = WithChannel({
    channelId: get(interaction, 'channel_id'),
  });

  const {
    step,
    setStep,
    previousStep,
    nextStep: nextStepDefault,
    isStepValid,
    setIsStepValid,
  } = WithSteps();

  const callAIPrompt = async (p_prompt) => {
    var postData = { 'prompt': p_prompt, 'segment_id': interaction.id };
    const { data } = await request({
      url: API_ENDPOINTS.promptai,
      method: 'POST',
      headers: { 'api-key': channel.uuid },
      body: JSON.stringify(postData)
    });
    console.log(data);
    setAiResponse(data);
    nextStepDefault();
  }
  const nextStep = async () => {
    nextStepDefault();
    if (step === 0 && prompt !== '') {
      await callAIPrompt(`
${promptContext}

${prompt}

${promptOutput}`);
    }
  };
  const previousStepX2 = async () => {
    previousStep();
    previousStep();
  }
  const setPromptAndStep = (event) => {
    event.preventDefault();
    setPrompt(event.target.value);
    setIsStepValid(event.target.value !== '');
  }
  useEffect(() => {
    async function init() {
      setStep(0);
      var i = await fetchInteraction(interactionId);
      console.log(i);
      var _promptContext = `Le titre de la participation est "${i.title}"`;
      if (i.text) {
        _promptContext = `${_promptContext} avec son texte de participation "${i.text}"`;
      }
      setPromptContext(_promptContext);
      setPromptOutput(`Le format de restitution de la demande est du markdown.`)
    }
    init();
  }, [interactionId]);

  return {
    isStepValid,
    step,
    nextStep,
    previousStepX2,
    setStep,
    prompt,
    setPromptAndStep,
    aiResponse,
    setPromptContext,
    promptContext,
    setPromptOutput,
    promptOutput
  };
};
