import styled from 'styled-components';
import { COLOR } from '../../constants/common';
import { Flex } from 'reflexbox';

export const PageTitle2 = styled(Flex)`
  font-size: 18px;
  color: ${COLOR.brownishGrey};
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 20px!important;
  padding-bottom: 5px;
  text-transform: capitalize;
  border-bottom: 1px solid ${COLOR.brownishGrey};
  `;
export const PageTitle2WithoutBorder = styled(Flex)`
  font-size: 18px;
  color: ${COLOR.brownishGrey};
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 20px!important;
  padding-bottom: 5px;
  text-transform: capitalize;
  `;
