import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { COLOR } from '../../constants/common';
import { Text } from '../text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from 'reflexbox';

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 328px;
  margin-left: auto !important;
  margin-bottom: 15px !important;
`;

const PointerOption = styled(Flex)`
  cursor: ${({ disabled, pointer }) => (disabled ? 'not-allowed' : pointer ? 'pointer' : 'unset')};
`;

const Option = styled(PointerOption)`
  flex: 1;
  flex-basis: 50%;
  margin-top: 15px !important;
  align-items: center;
`;

const OptionLabel = styled(Flex)`
  flex: 1;
  flex-basis: 50%;
  margin-top: 15px !important;
  align-items: center;
`;

const Checkbox = styled(PointerOption)`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  overflow: hidden;
  border: 0.5px solid ${COLOR.brownGrey};
  margin-right: ${({mr}) => mr || '20px'} !important;
  margin-left: ${({ml}) => ml || '0'} !important;
  ${({disabled}) => disabled && 'background-color: #EEE'} !important;

`;

export const Checkboxes = ({
  value,
  possibleValues,
  onChange,
  disabled,
  disabledException=[],
  doubleCheckbox,
  label,
  semiBold=true,
}) => {
  const { t } = useTranslation();

  const handleClick = (key, index) => {
    if (disabled === 'disabled' || disabledException.length > 0 && disabledException.indexOf(key) < 0) {
      return;
    }
    let newValue = value[key];
    if (doubleCheckbox) {
      if (newValue === true) {
        newValue = { active: true, mandatory: true };
      } else if (!newValue) {
        newValue = { active: false, mandatory: false };
      }
      newValue = { ...newValue };
      newValue[index] = !newValue[index];
      if (index === 'mandatory' && !newValue.active) {
        return;
      }
      if (index === 'active' && !newValue[index]) {
        newValue.mandatory = false;
      }
    } else {
      newValue = !newValue;
    }
    onChange(key, newValue);
  };

  return (
    <>
     {label ? (
          <Text
            flex={1}
            semiBold={semiBold}
            mt={'7px'}
          >
            {label} :
          </Text>
        ) : (
          <Box flex={1} />
        )}
    <Wrapper>
      <OptionLabel>
        <Text mr={'4px'} size={'xxxs'} bold>{t('Active')}</Text>
        <Text size={'xxxs'} bold>{t('Mandatory')}</Text>
      </OptionLabel>
      <OptionLabel>
        <Text mr={'4px'} size={'xxxs'} bold>{t('Active')}</Text>
        <Text size={'xxxs'} bold>{t('Mandatory')}</Text>
      </OptionLabel>
      {possibleValues.map(({ key, label }) => doubleCheckbox ? (
        <Option disabled={disabled === 'disabled' || disabledException.length > 0 && disabledException.indexOf(key) < 0 ? 'disabled': ''} key={key}>
          <Checkbox ml={'5px'} mr={'15px'} onClick={() => handleClick(key, 'active')} disabled={disabled === 'disabled' || disabledException.length > 0 && disabledException.indexOf(key)< 0  ? 'disabled': ''} pointer>
            {value[key] && value[key]['active'] && <Text>X</Text>}
          </Checkbox>
          <Checkbox onClick={() => handleClick(key, 'mandatory')} pointer disabled={!value[key] || !value[key]['active']}>
            {value[key] && value[key]['mandatory'] && <Text>X</Text>}
          </Checkbox>
          <Text upper>{t(label)}</Text>
        </Option>
      ) : (
        <Option onClick={() => handleClick(key)} disabled={disabled} key={key} pointer>
          <Checkbox disabled={disabled}>{value[key] && <Text>X</Text>}</Checkbox>
          <Text upper>{t(label)}</Text>
        </Option>
      ))}
    </Wrapper>
    </>
  );
};
