import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle2WithoutBorder } from '../../ui/page/PageTitle2';
import { COLOR } from '../../constants/common';
import { Box } from 'reflexbox';
import { Input } from '../../ui/input/Input';
import {
    END_TYPES,
    START_TYPES,
} from '../../models/interactions/withCreateInteraction';


export default function InteractionAvailability({
    interaction,
    setFrom,
    setField,
    setTo,
    disabled = false
}) {
    const { t } = useTranslation();

    return (
        <Box backgroundColor={COLOR['grey7']} padding={'10px'} margin={'20px 0px 20px 0px'}>
            <PageTitle2WithoutBorder>
                {t('Availability of interaction')}
            </PageTitle2WithoutBorder>
            <Input
                select
                value={interaction.from}
                name={'from'}
                onChange={setFrom}
                options={START_TYPES}
                description={t(
                    'You can choose whether your interaction is available now or whether it is scheduled to start at a later date.'
                )}
                label={t('From')}
                disabled={disabled}
            />
            <Input
                date
                value={interaction.start_date}
                name={'start_date'}
                onChange={setField}
                label={t('Start date')}
                disabled={interaction.from === 'now' || disabled}
            />
            <Input
                time
                value={interaction.start_date}
                name={'start_date'}
                onChange={setField}
                label={t('Start time')}
                disabled={interaction.from === 'now' || disabled}
            />
            <Input
                select
                value={interaction.to}
                name={'to'}
                onChange={setTo}
                options={END_TYPES}
                description={t(
                    'You can choose whether your interaction ends at a desired date or whether it is permanent.'
                )}
                label={t('Up to')}
                disabled={disabled}
            />
            <Input
                date
                value={interaction.end_date}
                name={'end_date'}
                onChange={setField}
                label={t('End date')}
                disabled={interaction.to === 'permanent' || disabled}
                hideIcon={interaction.to === 'permanent'}
            />
            <Input
                time
                value={interaction.end_date}
                name={'end_date'}
                onChange={setField}
                label={t('End time')}
                disabled={interaction.to === 'permanent' || disabled}
            />
        </Box>
    );
}
