import React from 'react';

export const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.001"
    height="15"
    viewBox="0 0 20.001 15"
  >
    <path
      fill="#333"
      d="M0 15v-2.368h12.5V15zm0-6.316V6.316h20v2.368zm0-6.316V0h20v2.368z"
      transform="translate(.001)"
    />
  </svg>
);
