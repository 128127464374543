import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Text } from '../text/Text';
import { COLOR } from '../../constants/common';
import { Relative } from '../box/Relative';
import { Absolute } from '../box/Absolute';
import { useTranslation } from 'react-i18next';

const CircleOuter = styled(Relative)`
  width: 14px;
  height: 14px;
  border: 1px solid ${COLOR.brownishGrey};
  border-radius: 50%;
`;

const CircleInner = styled(Absolute)`
  width: 10px;
  height: 10px;
  background: ${({ color }) => color || COLOR.yellowishOrange};
  top: 1px;
  left: 1px;
  border-radius: 50%;
`;

const OptionWrapper = styled(Flex)`
  cursor: pointer;
  align-items: center;
  flex-basis: 50%;
`;

const PointerOption = styled(Flex)`
  cursor: ${({ disabled, pointer }) => (disabled ? 'not-allowed' : pointer ? 'pointer' : 'unset')};
`;

const Option = styled(PointerOption)`
  flex: 1;
  flex-basis: 50%;
  margin-top: 15px !important;
  align-items: center;
`;

const OptionLabel = styled(Flex)`
  flex: 1;
  flex-basis: 50%;
  margin-top: 15px !important;
  align-items: center;
`;
const Checkbox = styled(PointerOption)`
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  overflow: hidden;
  border: 0.5px solid ${COLOR.grey4};
  margin: 0px!important;
  ${({ disabled }) => disabled && 'background-color: #EEE'} !important;

`;
const CheckboxWrapper = styled(Flex)`
  align-items: center;
  flex-basis: 50%;
`;
export const CheckboxPicker = ({ options, value, onChange, name, colorInner, px = '38px', inline = true, disabled = false }) => {
  const { t } = useTranslation();
  const handleClick = (key, value) => {
    onChange(name, !value);
  }
  return (
    <Flex>
      <CheckboxWrapper>
        {!disabled &&
          <Checkbox ml={'5px'} mr={'15px'} onClick={() => handleClick(name, value)} pointer>
            {value && <Text>X</Text>}
          </Checkbox>
        }
        {disabled &&
          <Checkbox ml={'5px'} mr={'15px'}>
            {value && <Text>X</Text>}
          </Checkbox>
        }
      </CheckboxWrapper>
    </Flex>
  );
};
