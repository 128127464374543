import React from 'react';
import { Text } from '../../ui/text/Text';
import { useTranslation } from 'react-i18next';
import {
  COLOR,
} from '../../constants/common';
import styled, { css } from 'styled-components';
import {
  IA_REQUEST_STEPS,
  WithRequestAI,
} from '../../models/interactions/withRequestAI';
import { Button } from '../../ui/button/Button';
import { Flex, Box } from 'reflexbox';
import { FlexCenter } from '../../ui/box/FlexCenter';
import { useModal } from '../../models/global/withModal';
import { Footer } from '../../ui/modal/Footer';
import { Link } from 'react-router-dom';
import { Steps } from '../../../features/steps/Steps';
import { Spinner } from '../../ui/spinner/Spinner';
import ReactMarkdown from 'react-markdown'

const ContentWrapper = styled(Box)`
  padding: 21.5px 74px 21.5px 32px;
  flex: 1;
  height: 80%;
`;

const ReactMarkdownVoxm = styled(ReactMarkdown)`
  font-size: 13px!important;
`;

const InnerScrollWrapper = styled(Flex)`
  overflow-y: auto;
  flex: 1;
  padding: 10px 10px 10px 10px;
  margin-right: 40px !important;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  height: 55px;
  align-items: center;
  border: 1px solid ${COLOR.veryLightPink};
  border-radius: 5px;
  padding: 8px;
  margin: 0px;
  position: relative;
  line-height: 20px;
  resize: none;
  color: ${COLOR.brownishGrey};
  font-size: 13px;
  &::placeholder {
    color: ${COLOR.brownGrey};
  }
  width: 100%;
`;
const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Header = styled(Flex)`
  height: 61px;
  border-bottom: 1px solid ${COLOR.veryLightPink};
  padding: 0 54px;
`;

const Container = styled(Flex)`
  flex: 1;
  max-height: calc(100% - 122px);
`;

export const RequestAI = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const {
    step,
    previousStepX2,
    nextStep,
    isStepValid,
    prompt,
    setPromptAndStep,
    aiResponse,
    setPromptContext,
    promptContext,
    setPromptOutput,
    promptOutput
  } = WithRequestAI();
  return (
    <Wrapper>
      <Header>
        <Steps steps={IA_REQUEST_STEPS} step={step} />
      </Header>
      <ContentWrapper>
        <Flex height={'100%'}>
          {step <= 1 &&
            <Container>
              <Box width="100%" marginLeft={'15px'}>
                <Text bold mb={'17px'} size={'h2'} capital>
                  {t("Assistant IA (version beta) : ")}
                </Text>
                <Text mb={'16px'}><br />
                  {t("Éléments de contexte")}
                </Text>
                <TextArea
                  value={promptContext}
                  onChange={e => setPromptContext(e.target.value)}
                  disabled={step > 0}
                />
                <Text mb={'16px'}><br />
                  {t("Format de sortie")}
                </Text>
                <TextArea
                  value={promptOutput}
                  onChange={e => setPromptOutput(e.target.value)}
                  disabled={step > 0}
                />
                <Text mb={'16px'}><br />
                  {t("Demande")}
                </Text>
                <TextArea
                  value={prompt}
                  onChange={e => setPromptAndStep(e)}
                  disabled={step > 0}
                />
                <Text mb={'26px'}><br />
                  {t("Limit de messages prise en compte : 100 (Version bêta)")}<br/><br/>
                  {t("Exemple de requêtes :")}
                  <ul>
                    <li>Afficher les commentaires les plus pertinents par rapport au thème de l'émission</li>
                    <li>...</li>
                  </ul>
                </Text>
                {step === 1 &&
                  <Spinner mh={"10vh"} />
                }
              </Box>
            </Container>
          }
          {step === 2 &&
            <>
              <InnerScrollWrapper>
                <Text bold mb={'17px'} size={'h2'} capital>
                  {t("Résultat de la requête")}
                </Text>
                <ReactMarkdownVoxm>{aiResponse}</ReactMarkdownVoxm>
              </InnerScrollWrapper>
            </>
          }

        </Flex>
      </ContentWrapper>
      <Footer>
        {step === 3 ? (
          <>
            <FlexCenter>
              <Button onClick={closeModal} mr={'25px'} theme={'light'}>
                {t('Back to the list')}
              </Button>
              <Button
                px={'52px'}
                as={Link}
                to={'/timeline'}
                onClick={closeModal}
              >
                {t('Go to distribution points')}
              </Button>
            </FlexCenter>
          </>
        ) : (
          <>
            {step > 1 ? (
              <Button onClick={previousStepX2} theme={'light'}>
                {t('Back')}
              </Button>
            ) : (
              <div />
            )}
            <FlexCenter>
              {step < 2 &&
                <Button onClick={nextStep} disabled={!isStepValid}>
                  {step === 1 ? t('Processing') : t('Next')}
                </Button>
              }
              {step === 2 &&
                <Button onClick={closeModal}>
                  {t('End and back to list')}
                </Button>
              }

            </FlexCenter>
          </>
        )}
      </Footer>
    </Wrapper>
  );
};
