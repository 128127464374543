import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { COLOR } from '../../../constants/common';

const Wrapper = styled.div`
  #text-tooltip {
    border-radius: 2px;
    font-size: 13px;
    padding: 2px 6px 3px 7px;
    text-transform: capitalize;
    max-width: 50vw;
  }
`;

export const TextTooltip = () => (
  <Wrapper>
    <ReactTooltip
      id={'text-tooltip'}
      backgroundColor={COLOR.brownisGrey}
      arrowColor={COLOR.transparent}
      place={'bottom'}
      offset={{ right: 3 }}
    />
  </Wrapper>
);
