import React, { useEffect } from 'react';

import { Menu } from '../menu/Menu';
import { Modal } from '../modal/Modal';
import { CreateInteraction } from '../interactions/CreateInteraction';
import { RequestAI } from '../interactions/AI';
import { AppWrapper } from '../../ui/appWrapper/AppWrapper';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "../"

export const Layout = ({ children }) => {
  const { fetchCurrentUser, isLoading, isLoggedIn } = useUser();
  useEffect(() => {
    if (!isLoggedIn) {
      fetchCurrentUser();
    }
  }, []);

  return (
    <AppWrapper>
      <Modal id={'interaction'}>
        <CreateInteraction />
      </Modal>
      <Modal id={'ai'}>
        <>
        <RequestAI />
      </>
      </Modal>
      <Menu />
      {isLoading ? null : children}
    </AppWrapper>
  );
};
